import React from 'react'

function İtemBanner() {
  return (
    <div>
        <div className="item-banner">
            <div className="container-fluid">
                <div className="row text-start">
                    <div className="col-12">
                       <h1>ARAÇ FİLOMUZ</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default İtemBanner


